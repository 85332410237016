import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import {
  CreateOrganizationDto,
  CredentialInput,
  CredentialDeleteInput,
  Organization,
  OrganizationFilter,
  UpdateOrganizationDto
} from 'src/app/graphql/frontend-data-graphql';

export const OrganizationUiActions = createActionGroup({
  source: 'Organization UI',
  events: {
    Select: props<{ identifier: string | null }>(),
    Add: emptyProps(),
    FindAllTriggered: props<{ filter: OrganizationFilter; q: string }>(),
    CreateTriggered: props<{ input: CreateOrganizationDto }>(),
    UpdateOneTriggered: props<{ identifier: string; update: UpdateOrganizationDto }>(),
    DeleteOneTriggered: props<{ identifier: string }>()
  }
});

export const CredentialUiActions = createActionGroup({
  source: 'Credential UI',
  events: {
    CreateTriggered: props<{ input: CredentialInput }>(),
    UpdateOneTriggered: props<{ update: CredentialInput }>(),
    DeleteOneTriggered: props<{ input: CredentialDeleteInput }>()
  }
});

export const OrganizationApiActions = createActionGroup({
  source: 'Organization API',
  events: {
    FindAllSucceeded: props<{ items: Organization[]; q: string }>(),
    CreateSucceeded: props<{ identifier: string }>(),
    Created: props<{ item: Organization }>(),
    UpdateOneSucceeded: props<{ identifier: string }>(),
    UpdatedOne: props<{ item: Organization }>(),
    DeleteOneSucceeded: props<{ identifier: string }>(),
    DeletedOne: props<{ identifier: string }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});

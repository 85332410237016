import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on, select } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { Organization } from 'src/app/graphql/frontend-data-graphql';

import { OrganizationApiActions, OrganizationUiActions } from './organization.actions';

export interface OrganizationState extends EntityState<Organization> {
  selectedId: string | null;
  // searchToken: string;
  errors: readonly GraphQLError[];
}

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: p => p.identifier,
  sortComparer: false
});

const state: OrganizationState = adapter.getInitialState({ selectedId: null, errors: [] });

export const organizationReducer = createReducer<OrganizationState>(
  state,
  on(OrganizationUiActions.select, (state, { identifier }): OrganizationState => {
    return { ...state, selectedId: identifier };
  }),
  on(OrganizationUiActions.add, (state): OrganizationState => {
    return { ...state, selectedId: null };
  }),
  on(OrganizationApiActions.findAllSucceeded, (state, { items, q }): OrganizationState => {
    return adapter.setAll(items, { ...state, searchToken: q, errors: [] });
  }),
  on(OrganizationApiActions.createSucceeded, OrganizationApiActions.updateOneSucceeded, (state, { identifier }): OrganizationState => {
    return { ...state, selectedId: identifier, errors: [] };
  }),
  on(OrganizationApiActions.created, (state, { item }): OrganizationState => {
    return adapter.addOne(item, { ...state });
  }),
  on(OrganizationApiActions.updatedOne, (state, { item }): OrganizationState => {
    return adapter.setOne(item, { ...state });
  }),
  on(OrganizationApiActions.deleteOneSucceeded, (state, { identifier }): OrganizationState => {
    return { ...state, selectedId: null, errors: [] };
  }),
  on(OrganizationApiActions.deletedOne, (state, { identifier }): OrganizationState => {
    return adapter.removeOne(identifier, { ...state });
  }),
  on(OrganizationApiActions.requestFailed, (state, { errors }): OrganizationState => {
    return adapter.removeAll({ ...state, selectedId: null, errors });
  })
);

export const featureKey = 'organization';

export const feature = createFeature({
  name: featureKey,
  reducer: organizationReducer,
  extraSelectors: ({ selectOrganizationState, selectEntities, selectSelectedId }) => ({
    ...adapter.getSelectors(selectOrganizationState),
    selectManyOrganizations: createSelector(selectEntities, state => Object.values(state) as Organization[]),
    selectSelectedOrganization: createSelector(selectEntities, selectSelectedId, (entities, id) => (id ? (entities[id] ?? null) : null))
  })
});

export const { selectManyOrganizations, selectSelectedOrganization } = feature;
